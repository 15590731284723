import usePrefix from '@/hooks/usePrefix';
import { IAirport, IGuide } from '@/types';
import { Button, Divider } from 'antd';
import Link from 'next/link';
import { DownOutlined, UpOutlined } from '@/components2/Icons';
import React, { ReactElement, useState } from 'react';

export interface IGuideList {
  airports: { result: IAirport[]; guides: IGuide[] };
  grid?: number;
  gap?: number;
}

function GuidesList({ airports, grid = 3, gap = 2 }: IGuideList): ReactElement {
  const prefix = usePrefix();
  return (
    <div
      className={`grid grid-cols-1 md:grid-cols-${grid} gap-y-2 gap-x-${gap} place-items-start`}
    >
      {airports?.guides?.map((guide: IGuide) => (
        <Link
          href={`${prefix}/guide/${guide.guide_friendly_url}`}
          key={guide.guide_id}
          className="text-primary hover:underline hover:text-primary"
          title={guide.guide_name}
        >
          • {guide.guide_name}
        </Link>
      ))}
    </div>
  );
}

function Guides({ airports }): ReactElement {
  const [showAllGuides, setShowAllGuides] = useState(false);
  return (
    <div className="container flex flex-col justify-center text-center w-full md:w-3/4 gap-4 my-4 mx-auto">
      <div
        className={`overflow-hidden ${
          showAllGuides ? 'h-auto pt-1.5' : 'h-14'
        } flex flex-col px-2 justify-center text-center border-y border-gray-300`}
      >
        <Button
          size="large"
          className="border-none bg-transparent md:text-xl text-primary w-full flex flex-row justify-between items-center"
          onClick={() => setShowAllGuides(!showAllGuides)}
        >
          <h2 className="text-sm md:text-base font-bold text-left">
            Our Guides
          </h2>

          {showAllGuides ? <UpOutlined /> : <DownOutlined />}
        </Button>
        <div className={`w-full pt-4 ${showAllGuides ? 'visible' : 'hidden'}`}>
          <GuidesList airports={airports} />
        </div>
      </div>
    </div>
  );
}

export default Guides;
